export const LINKS = {
  privacyPolicy: {
    label: 'Privacy Policy',
    href: 'https://witgritfit.com/privacy-policy/',
  },
  careersConnect: {
    label: 'Careers Connect',
    href: 'https://content.mycareersfuture.gov.sg/careercoaching/',
  },
  abundanceMindset: {
    label: 'abundance mindset',
    href: 'https://www.forbes.com/sites/carolinecastrillon/2020/07/12/5-ways-to-go-from-a-scarcity-to-abundance-mindset/',
  },
  growthMindset: {
    label: 'growth mindset',
    href: 'https://www.mindsethealth.com/matter/growth-vs-fixed-mindset',
  },
  criticalCoreSkills: {
    label: 'Critical Core Skills',
    href: 'https://www.myskillsfuture.gov.sg/content/portal/en/career-resources/career-resources/education-career-personal-development/Critical_Core_Skills_What_They_Are_And_Why_They_Matter.html',
  },
  criticalCoreSkills2: {
    href: 'https://www.myskillsfuture.gov.sg/content/portal/en/career-resources/career-resources/education-career-personal-development/2022_Critical_Core_Skills.html',
    label: 'Critical Core Skills',
  },
  additionalResources: {
    href: 'https://www.skillsfuture.gov.sg/initiatives/early-career/criticalcoreskills',
    label: 'this link',
  },
  skillSet: {
    href: 'https://www.skillsfuture.gov.sg/docs/default-source/initiatives/critical-core-skills/ccs-reference-documents.zip?sfvrsn=f53e7f7a_3/',
    label: 'here',
  },
  termsOfUse: {
    label: 'Terms of Use',
    href: 'https://witgritfit.com/terms-of-use/',
  },
  careerDesignWorkshop: {
    label: 'Career Design Workshop',
    href: 'https://witgritfit.com/career-design-life-simulation-digital/',
  },
  careerDesignCanvas: {
    label: 'WIT GRIT FIT Career Design Canvas',
    href: 'https://platform.witgritfit.com/career-canvas/',
  },
  careerAdventure: {
    label: 'Career Adventure tools',
    href: 'https://witgritfit.com/my-career-adventure/',
  },
  linkedin: {
    label: 'Linkedin',
    href: 'https://www.linkedin.com/company/witgritfit',
  },
  facebook: {
    label: 'Facebook',
    href: 'https://www.facebook.com/avidadventures',
  },
  stayingRelevant: {
    label: 'Staying Relevant',
    href: 'https://www.skillsfuture.gov.sg/skills-framework/criticalcoreskills',
  },
};

interface ILink {
  label: string;
  href: string;
}

export const createLink = (href: string, label: string): string => {
  return `<a href="${href}" target="_blank" rel="noreferrer">${label}</a>`;
};

export const createLinkList = (list: ILink[]) =>
  list.map(({ label, href }) => `<li>${createLink(href, label)}</li>`).join('');
