import styled from 'styled-components';

import { Loader } from './loader';

const Styles = {
  Root: styled.div`
    width: 100vw;
    height: 100vh;
    display: grid;
    justify-content: center;
    align-items: center;
  `,

  Loader: styled(Loader)`
    & svg {
      width: 100px;
      height: 100px;
    }
  `,
};

export const ScreenLoader = () => (
  <Styles.Root>
    <Styles.Loader />
  </Styles.Root>
);
