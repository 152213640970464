export const PROMISES_AREA = {
  printCaasPdf: 'get-caas-pdf',
  getCaasQuestionList: 'get-caas-questions',
  auth: 'sign-in',
  authSignUp: 'sign-up',
  logOut: 'log-out',
  getCaasResult: 'get-caas-results',
  sendCaasAnswers: 'send-caas-answers',
  getCaasCsv: 'get-caas-csv',
  getCareerCanvasCsv: 'get-career-canvas-csv',
  getUserTrainers: 'get-user-trainers',
  requestTrainer: 'request-trainer',
  getGroupsByTrainer: 'get-groups-by-trainer',
  inviteStudent: 'invite-student',
  inviteFromSuperAdmin: 'invite-from-super-admin',
  sendCanvasAnswers: 'send-canvas-answers',
  printCareerCanvasPdf: 'print-career-canvas-pdf',
  bulkInvite: 'bulk-invite',
  getAllUsers: 'get-all-users',
  getAllUsersNtu: 'get-all-users-ntu',
  getInvitations: 'get-invitations',
  getAllUsersCsv: 'get-all-users-csv',
  getAllUsersCsvNtu: 'get-all-users-csv-ntu',
  getUserHasPassword: 'has-password',
  sendEmailWithCode: 'send-code',
  authGameAdmin: 'auth-game-admin',
  getAllTrainers: 'get-all-trainers',
  getAllTrainersCsv: 'get-all-trainers-csv',
  getLimitSetting: 'get-limits/:id',
  setLimitsTrainer: 'set-limits',
  createGroup: 'create',
  renameGroup: 'rename',
  deleteGroup: 'delete-group',
  getCareerDesignGameReports: 'get-career-design-game-reports',
};
