import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { ROUTES } from '@constants/routes';

import { SignUpPage } from '@screens/sign-up-page';
import { SignInPage } from '@screens/sign-in-page';
import { MainPage } from '@screens/main-page';
import { ErrorPage } from '@screens/error-page';
import { PlatformPageLazy } from '@screens/platform-page';
import { QuizPageLazy } from '@screens/caas-quiz/quiz-page';
import { ResultPage } from '@screens/caas-quiz/result-page';
import { ResetPasswordLazy } from '@screens/reset-password';
import { CareerAdventure } from '@screens/career-adventure';
import {
  CanvasResultsLazy,
  CanvasGateLazy,
  CanvasResultsNtuLazy,
} from '@screens/career-canvas';

import { UpdateResetedPassword } from '@screens/update-reseted-password';
import { HomePageSlider } from '@screens/home-page';

import { PrivateRoute } from './private-route';
import { GameAuthenticator } from '@screens/game-authenticator';

export const AppRouter: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <PrivateRoute exact path={ROUTES.main} component={MainPage} />

      <Route exact path={ROUTES.welcome} component={HomePageSlider} />

      <PrivateRoute
        exact
        path={[ROUTES.gameAuth, ROUTES.gameCorpAuth]}
        component={GameAuthenticator}
      />

      <PrivateRoute
        exact
        redirectTo={ROUTES.ntuSignUp}
        path={[ROUTES.gameNTUAuth]}
        component={GameAuthenticator}
      />

      <Route exact path={ROUTES.careerFlexResults} component={ResultPage} />

      <PrivateRoute exact path={ROUTES.careerFlex} component={QuizPageLazy} />

      <PrivateRoute path={ROUTES.platform} component={PlatformPageLazy} />

      <Route
        exact
        path={[ROUTES.signUp, ROUTES.ntuSignUp]}
        component={SignUpPage}
      />

      <Route
        exact
        path={[ROUTES.signIn, ROUTES.ntuSignIn]}
        component={SignInPage}
      />

      <Route exact path={ROUTES.errorPage} component={ErrorPage} />

      <Route exact path={ROUTES.resetPassword} component={ResetPasswordLazy} />

      <PrivateRoute
        exact
        path={ROUTES.careerDesignCanvas}
        component={CanvasGateLazy}
      />

      <PrivateRoute
        exact
        redirectTo={ROUTES.ntuSignUp}
        path={ROUTES.careerDesignCanvasNtu}
        component={CanvasGateLazy}
      />

      <Route
        exact
        path={ROUTES.careerDesignCanvasResults}
        component={CanvasResultsLazy}
      />

      <Route
        exact
        path={ROUTES.careerDesignCanvasResultsNtu}
        component={CanvasResultsNtuLazy}
      />

      <Route
        exact
        path={ROUTES.updatePassword}
        component={UpdateResetedPassword}
      />

      <Route
        exact
        path={ROUTES.myCareerAdventure}
        component={CareerAdventure}
      />

      <Redirect to={ROUTES.main} />
    </Switch>
  </BrowserRouter>
);
