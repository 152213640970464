export const REGEXPS = {
  name: /^[a-zA-Z0-9#$%&'()*+,-./:; <=>?@[\\\]^_`{|}~]+$/,
  email:
    /^(([^<>()\]\\.!#$%&’*+=?^{|}~,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  emailNtu:
    /^[A-Za-z0-9._%+-]+@(e\.ntu\.edu\.sg|ntu\.edu\.sg|staff\.main\.ntu\.edu\.sg|student\.main\.ntu\.edu\.sg|nure\.ua)$/,
  password:
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.,^()_+=;:<>'"~])[A-Za-z\d@$!%*#?&.,^()_+=;:<>'"~]{8,}$/,
  phone: /^(\+\d{1,3}[- ]?)?\d{10}$/,
  onlyNumbers: /^(^[1-9][0-9]*|(unlimited))+$/,
  dateFormat: /^\d{2}[./-]\d{2}[./-]\d{4}$/,
  time: /(2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/,
  dayHoursMinute:
    /^[0-9]+(d(?=$|\s$|(\s([0-9]){1,2}[h]$)|(\s[0-9]{1,2}[m]$)|(\s[0-9]{1,2}[h]\s[0-9]{1,2}[m]$)))|(^[0-9]{1,2}h(?=$|\s$|\s[0-9]{1,2}[m]$))|(^[0-9]{1,2}m(?=$|\s$))|(unlimited)/i,
};
