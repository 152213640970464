import { HTMLAttributes } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    animation: ring 1.2s linear infinite;
  }

  @keyframes ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Loader = (props: HTMLAttributes<HTMLDivElement>) => (
  <Root {...props}>
    <svg width="51" height="51" viewBox="0 0 51 51" fill="none">
      <circle
        cx="25.725"
        cy="25.725"
        r="22.725"
        stroke="#8DC63F"
        strokeWidth="5"
      />
      <path
        d="M3 25.7251C3 38.2758 13.1743 48.4501 25.725 48.4501C33.9651 48.4501 41.1808 44.0644 45.1654 37.5"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <path
        d="M3.11243 28C4.25371 39.4825 13.9421 48.4501 25.725 48.4501C33.0968 48.4501 39.6487 44.94 43.8007 39.5"
        stroke="#00AEEF"
        strokeWidth="5"
        strokeLinecap="round"
      />
    </svg>
  </Root>
);
