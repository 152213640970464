import {
  DocumentData,
  FirestoreError,
  collection,
  doc,
  onSnapshot,
} from 'firebase/firestore';

import { IRealtimeConfig } from 'typings';

import { db } from './firebase-init';

export class AppFirestore {
  private static getFirestore() {
    return db;
  }

  private static rootCollection(collectionName: string) {
    return collection(this.getFirestore(), collectionName);
  }

  private static configCollection() {
    return this.rootCollection('config');
  }

  private static configAppRealtimeDoc() {
    return doc(this.configCollection(), 'app-realtime');
  }

  public static subsribeToConfigAppRealtimeData(
    onChange: (data: IRealtimeConfig) => void,
    onError?: (error: FirestoreError) => void
  ) {
    const unsubscribe = onSnapshot<DocumentData, DocumentData>(
      this.configAppRealtimeDoc(),
      (doc) => {
        onChange(doc.data() as IRealtimeConfig);
      },
      onError
    );

    return { unsubscribe };
  }
}
