import { FC, useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';

import { useAppSelector } from '@services/hooks/redux';

import { DOMAINS_MAP } from './game-authenticator.constants';
import { moveAndAuthInGame } from './game-authenticator.utils';

export const GameAuthenticator: FC<RouteComponentProps> = () => {
  const location = useLocation();

  const email = useAppSelector((selector) => selector.user.email);
  const fullName = useAppSelector(
    (selector) => selector.user.firstName + ' ' + selector.user.lastName
  );

  useEffect(() => {
    const domain = Object.keys(DOMAINS_MAP).includes(location.pathname)
      ? DOMAINS_MAP[location.pathname as keyof typeof DOMAINS_MAP]
      : null;

    const request = async () => {
      if (!domain) {
        return;
      }

      moveAndAuthInGame(location.pathname, domain, { email, fullName });
    };

    request();
  }, [location.pathname]);

  return null;
};
