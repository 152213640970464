import React from 'react';

import { Icon } from '@components/icon';

import { IButtonProps, ILinkButtonProps } from './button.typings';

import { ButtonStyles } from './button.styles';

export const Button: React.FC<IButtonProps> = (props) => {
  const { title, iconType, isIconRight } = props;

  return (
    <ButtonStyles.Button {...props} isIconRight={isIconRight}>
      {iconType && <Icon type={iconType} />}
      <span>{title}</span>
    </ButtonStyles.Button>
  );
};

export const LinkButton: React.FC<ILinkButtonProps> = (props) => {
  const { children, iconType, isIconRight } = props;

  return (
    <ButtonStyles.Link {...props} isIconRight={isIconRight}>
      {iconType && <Icon type={iconType} />}
      <span>{children}</span>
    </ButtonStyles.Link>
  );
};
