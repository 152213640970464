import axios from 'axios';
import styled from 'styled-components';
import { trackPromise } from 'react-promise-tracker';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';

import { PROMISES_AREA } from '@constants/promises-area';
import { useAppDispatch } from '@services/hooks/redux';
import { storageService } from '@services/storage/storage';
import { msAuth } from '@services/user.service';
import { loginUser } from '@store/reducers/user.slice';
import { Button } from '@components/button';
import { COLORS } from '@styles/colors';
import { errorMessage } from '@constants/pop-up-messages';

const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MS_CLIENT_ID || '',
  },
};

const ButtonStyled = styled(Button)`
  margin: 24px 0;
  width: 337px;
  padding: 10px;
  background-color: ${COLORS.blueDarker};
`;

const pca = new PublicClientApplication(configuration);

export const MicrosoftButton = () => {
  const dispatch = useAppDispatch();

  if (!process.env.REACT_APP_MS_CLIENT_ID) {
    return null;
  }

  const onClick = async () => {
    try {
      const authResult = await pca.loginPopup({
        prompt: 'select_account',
        scopes: ['user.read'],
      });

      const { data } = await trackPromise(
        msAuth({ token: authResult.accessToken }),
        PROMISES_AREA.auth
      );

      dispatch(loginUser(data.user));

      return storageService.setToken(data.token, true);
    } catch (error) {
      console.error(error);

      if (axios.isAxiosError(error)) {
        return errorMessage(error?.response?.data.message).fire();
      }
    }
  };

  return (
    <ButtonStyled
      title="Sign in with Microsoft"
      iconType="microsoft"
      color={COLORS.default}
      onClick={onClick}
    />
  );
};
