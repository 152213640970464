import * as yup from 'yup';

import { REGEXPS } from '@constants/regexp';

export const initialSignUp = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
};

export const initialSignUpState: IUserSignUp = initialSignUp;

export const getUserFormSchema = (isNtu: boolean) =>
  yup.object().shape({
    firstName: yup
      .string()
      .max(25)
      .trim()
      .required('This field cannot be empty')
      .matches(REGEXPS.name, 'Please enter valid name'),
    lastName: yup
      .string()
      .max(25)
      .trim()
      .required('This field cannot be empty')
      .matches(REGEXPS.name, 'Please enter valid surname'),
    password: yup
      .string()
      .required('This field cannot be empty')
      .matches(
        REGEXPS.password,
        'Min 8 characters with at least 1 upper and lower case, number and special character @$!%*#?&.,^()_+=;:<>\'"~'
      ),
    email: yup
      .string()
      .max(50)
      .trim()
      .required('This field cannot be empty')
      .matches(
        isNtu ? REGEXPS.emailNtu : REGEXPS.email,
        isNtu
          ? 'Please, enter your NTU email which ends with @e.ntu.edu.sg, @ntu.edu.sg, @staff.main.ntu.edu.sg or @student.main.ntu.edu.sg'
          : 'Please enter valid email'
      ),
  });
