import { Suspense } from 'react';

import { ScreenLoader } from './screen-loader';

interface IProps {
  children?: React.ReactNode;
}

export const SuspenseWrapper = (props: IProps) => {
  const { children } = props;

  return <Suspense fallback={<ScreenLoader />}>{children}</Suspense>;
};
