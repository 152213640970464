import { lazy } from 'react';

import { SuspenseWrapper } from '@components/suspense-wrappper';

const Element = lazy(() => import('./reset-password'));

export const ResetPasswordLazy = () => (
  <SuspenseWrapper>
    <Element />
  </SuspenseWrapper>
);
