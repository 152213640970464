import React, { FC } from 'react';
import { Formik } from 'formik';

import { PromiseLoader } from '@components/promise-loader';
import { Button } from '@components/button';
import { SocialAuth } from '@components/social-auth';
import { COLORS } from '@styles/colors';
import { STRINGS } from '@constants/strings';
import { PROMISES_AREA } from '@constants/promises-area';

import { getUserFormSchema } from './form.constants';
import { useSignUpState } from './form.state';
import { FormStyles as Styled } from './form.styles';
import { TextField } from '@components/text-field';

export const SignUpForm: FC = () => {
  const {
    isNTU,
    isExpanded,
    onChangeUser,
    signUpHandler,
    redirectToSignIn,
    switchShowFields,
    toggleShowPassword,
    state,
    isPasswordShown,
  } = useSignUpState();

  return (
    <Formik
      initialValues={state}
      validateOnChange
      onSubmit={signUpHandler}
      validationSchema={getUserFormSchema(isNTU)}
    >
      {({
        errors,
        touched,
        handleBlur,
        handleChange,
        isValid,
        handleSubmit,
      }) => {
        const handleUserChange = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          handleChange(event);
          onChangeUser(event);
        };

        return (
          <>
            <Styled.FormCard>
              <Styled.Title>{STRINGS.signUp.title}</Styled.Title>
              {/* <Styled.FormLabel>{STRINGS.signUp.label}</Styled.FormLabel> */}
              <SocialAuth isNTU={isNTU} />

              <Styled.Fields isShow={isExpanded}>
                <TextField
                  type="text"
                  name="firstName"
                  placeholder="* First Name"
                  value={state.firstName}
                  withBorder
                  onChange={handleUserChange}
                  onBlur={handleBlur}
                  error={
                    touched.firstName && errors.firstName
                      ? errors.firstName
                      : ''
                  }
                />
                <TextField
                  type="text"
                  name="lastName"
                  placeholder="* Last Name"
                  value={state.lastName}
                  withBorder
                  onChange={handleUserChange}
                  onBlur={handleBlur}
                  error={
                    touched.lastName && errors.lastName ? errors.lastName : ''
                  }
                />
                <TextField
                  type="text"
                  name="email"
                  placeholder="* Email"
                  value={state.email}
                  withBorder
                  onChange={handleUserChange}
                  onBlur={handleBlur}
                  error={touched.email && errors.email ? errors.email : ''}
                />
                <div style={{ position: 'relative' }}>
                  <TextField
                    type={isPasswordShown ? 'text' : 'password'}
                    name="password"
                    placeholder="* Password"
                    value={state.password}
                    withBorder
                    onChange={handleUserChange}
                    onBlur={handleBlur}
                    error={
                      touched.password && errors.password ? errors.password : ''
                    }
                    iconNew={
                      state.password.length ? (
                        <Styled.Icon
                          type={isPasswordShown ? 'eye' : 'eyeBlocked'}
                          error={
                            touched.password && errors.password
                              ? errors.password
                              : ''
                          }
                          onClick={toggleShowPassword}
                        />
                      ) : null
                    }
                  />
                </div>
              </Styled.Fields>

              <PromiseLoader area={PROMISES_AREA.authSignUp}>
                <Styled.Footer>
                  <Button
                    title={STRINGS.button.signUp}
                    onClick={isExpanded ? handleSubmit : switchShowFields}
                    color={COLORS.lightBlue}
                    type={isExpanded ? 'submit' : 'button'}
                    variant={isExpanded ? 'primary' : 'text'}
                    isDisabled={isExpanded ? !isValid : false}
                    minWidth={150}
                  />

                  <Button
                    title={STRINGS.button.returnSignIn}
                    onClick={redirectToSignIn}
                    variant="text"
                    color={COLORS.lightBlue}
                  />
                </Styled.Footer>
              </PromiseLoader>
            </Styled.FormCard>
          </>
        );
      }}
    </Formik>
  );
};
