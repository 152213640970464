import { GoogleButton } from './components/google-button';
import { FacebookButton } from './components/facebook-button';

import { StyledSocialAuth as Styled } from './social-auth.styles';
import { MicrosoftButton } from './components/microsoft-button';

interface IProps {
  isNTU: boolean;
}

export const SocialAuth = (props: IProps) => {
  const { isNTU } = props;

  const RenderButtons = isNTU ? (
    <MicrosoftButton />
  ) : (
    <>
      <MicrosoftButton />
      <FacebookButton />
      <GoogleButton />
    </>
  );

  return (
    <Styled.Wrapper>
      <Styled.WrapperButtons>{RenderButtons}</Styled.WrapperButtons>
      <Styled.Title>
        <span>or</span>
      </Styled.Title>
    </Styled.Wrapper>
  );
};
