import { useAppSelector } from './redux';

const NTU_EMAIL_DOMAINS = [
  'e.ntu.edu.sg',
  'ntu.edu.sg',
  'staff.main.ntu.edu.sg',
  'student.main.ntu.edu.sg',
  'nure.ua',
];

export const useNtuUser = () => {
  const email = useAppSelector((redux) => redux.user.email);
  const isNtuFlag = useAppSelector((redux) => redux.user.isNtu);

  const isNtuUser = Boolean(
    NTU_EMAIL_DOMAINS.includes(email.split('@')[1]?.trim()) || isNtuFlag
  );

  return { isNtuUser };
};
